import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
import GIF from "../../assets/Digitale_Grusskarte_2024_vs8-optimize.gif";

// import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  //description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  //hubSpotForm,
} from "../subpage.module.scss";

const GreetingsPage = () => {
  return (
    <Layout pageTitle="HoHoHo" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <h1 style={{ marginBottom: 45 }}>Guetä Rutsch :)</h1>

                <img
                  src={GIF}
                  alt="Gif Digiale Weihnachts und Neujahr Grüsse"
                />
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default GreetingsPage;
